import { UserProfile } from 'fitbeat.gym-core';
import { Gym, IInvoice, IInvoiceItem, MembersReportData, ProductCategory, UserAccountDetail } from 'fitbeat.models';
import moment from 'moment';
import { Resize } from 'react-table';
import { createSelector } from 'reselect';
import { IAppStore } from '../rootReducer';
import { getFormattedAmount } from '../shared/helpers/formatHelper';
import { ISingleField } from './components/MemberDetailsSingleField';
import { flattenDataForReport, userAvatarSrc } from './config';
import { buildColumnsFromAccessors } from './filters/members';
import { convertDataToViews, IMemberColumn } from './storeTypes';

export const getMemberDetailsData = (state: IAppStore) => {
  return state.members.data.memberDetailsData;
};

export const getMemberHasValidDefaultPaymentMethod = (state: IAppStore) => {
  const defaultPaymentMethod = state.members.data.memberAccountData?.userInfo.defaultPaymentMethod;

  if (!defaultPaymentMethod?.data) {
    return false;
  }

  if (defaultPaymentMethod.type === 'card') {
    const card = defaultPaymentMethod.data.card;

    if (!card) {
      return false;
    }

    return !isPaymentMethodExpired(card.exp_year, card.exp_month);
  }

  return true;
};

export const getInvoiceTotalDueIsZero = (state: IAppStore) => {
  const invoice = state.accountActions.data.buyMerchandise.invoice;
  return (!!invoice && invoice.due === 0);
};

export const getDefaultPaymentMethodDisplayText = (state: IAppStore) => {
  const defaultPaymentMethod = state.members.data.memberAccountData?.userInfo.defaultPaymentMethod;

  if (!defaultPaymentMethod?.data) {
    return undefined;
  }

  if (defaultPaymentMethod.type === 'card') {
    const card = defaultPaymentMethod.data.card;

    if (!card) {
      return undefined;
    }

    return `${card.brand} ****${card.last4} |
      ${isPaymentMethodExpired(card.exp_year, card.exp_month) ?
        'Expired' : 'Expires'} ${card.exp_month.toString().padStart(2, '0')}/${card.exp_year.toString().slice(-2)}`;
  }

  if (defaultPaymentMethod.type === 'debit') {
    const debit = defaultPaymentMethod.data.au_becs_debit;
    if (!debit) {
      return undefined;
    }

    return `Direct Debit: ${debit.bsb_number}*****${debit.last4}`;
  }

  return undefined;
};

export const defaultPaymentMethodDisplayTextSelector = createSelector(
  [getDefaultPaymentMethodDisplayText],
  (displayText) => displayText);

const isPaymentMethodExpired = (expirationYear: number, expirationMonth: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const yearPassed = expirationYear >= currentYear;
  const monthPassed = !(expirationYear === currentYear && currentMonth >= expirationMonth);

  return !(yearPassed && monthPassed);
};

export const savedReportColumnWidth = createSelector([
  (report: MembersReportData) => report],
  (report: MembersReportData) => {
    const { memberColumnData } = report;
    const memberTableColumns = memberColumnData.filter((column: any) => column.used);
    const resizeData: Resize[] = [];
    memberTableColumns.forEach((column: IMemberColumn) => {
      resizeData.push({
        id: column.accessor ? column.accessor : '',
        value: column.width,
      });
    });
    return resizeData;
  },
);

export const numberOfRowsInReportsSelector = createSelector([
  (state: IAppStore) => state.members.data.dashboardMemberReportData,
  (state: IAppStore) => state.members.data.memberDetailsData,
  (state: IAppStore) => state.members.data.allWorkouts,
  (state: IAppStore) => state.members.data.filterDataFromMembersData,
  (state: IAppStore) => state.adminSettings.data.selectedGymName,
  (state: IAppStore) => state.adminSettings.data.gyms,
],
  (reports, memberDetailsData, allWorkouts, filterData, currentGymName, gyms): Map<string, number> => {
    const numberOfRowsPerReportId = new Map<string, number>();
    const currentGym = gyms.find((g: Gym) => g.name === currentGymName);

    for (const report of reports) {
      const reportId = report.reportId;
      if (!currentGym || !memberDetailsData || !allWorkouts || !filterData || !reports) {
        numberOfRowsPerReportId.set(reportId, 0);
        continue;
      }
      try {
        const rowsForReport = flattenDataForReport(
          memberDetailsData,
          allWorkouts,
          report);

        let resultSet = rowsForReport.map((row: any) => ({
          ...row,
          _original: row,
        }));

        report.filters.forEach((filter) => {
          const columnDetails = buildColumnsFromAccessors(filter.id, filterData, currentGym) as any;

          if (columnDetails && columnDetails.filterMethod) {
            if (columnDetails.filterAll) {
              resultSet = columnDetails.filterMethod(filter, resultSet);
            } else {
              const tempSet = resultSet.filter((row: any) => columnDetails.filterMethod(filter, row));
              resultSet = tempSet;
            }
          }
        });
        numberOfRowsPerReportId.set(reportId, resultSet.length);
      } catch (e) {
        numberOfRowsPerReportId.set(reportId, NaN);
      }
    }
    return numberOfRowsPerReportId;
  },
);

const getMemberAddressDetails = (memberDetails: UserProfile | undefined) => {
  if (!memberDetails) {
    return [];
  }

  return [
    { label: 'Unit', value: memberDetails.unit },
    { label: 'Address Line 1', value: memberDetails.addressLine1 },
    { label: 'Address Line 2', value: memberDetails.addressLine2 },
    { label: 'Suburb', value: memberDetails.suburb },
    { label: 'Post Code', value: memberDetails.postCode },
    { label: 'State', value: memberDetails.state },
  ];
};

const getMemberPersonalDetails = (memberDetails: UserProfile | undefined): {
  leftList: { label: string, value: string }[],
  rightList: ISingleField[],
} => {
  if (!memberDetails) {
    return { rightList: [], leftList: [] };
  }
  return {
    leftList: [
      { label: 'Nickname', value: memberDetails.nickname },
      { label: 'HR Monitor', value: memberDetails.heartRateMonitorIdentifier },
      { label: 'Date of Birth', value: memberDetails.dateOfBirth },
      { label: 'Gender', value: memberDetails.gender?.toString() },
      { label: 'Fitness Goal', value: memberDetails.longTermGoal?.toString() },
      { label: 'Weight (Kg)', value: memberDetails.weight?.toString() },
      { label: 'Height (cm)', value: memberDetails.height?.toString() },
    ],
    rightList: [
      { label: 'User ID', value: memberDetails.uid },
      { label: 'Customer ID', value: memberDetails.stripeUserId },
      { label: 'Signup Source', value: memberDetails?.signupSource?.source },
      { label: 'Attribution Source', value: memberDetails?.signupSource?.attributionSourceId },
      ...(memberDetails.continueSignupToken ?
        [
          {
            label: 'Continue Signup Link',
            value:  memberDetails.continueSignupLink,
          },
          {
            label: 'Continue Signup QR',
            value : memberDetails.continueSignupLink,
            showQrCode: true,
          },
        ]:
        []
      ),
      { label: 'Company Id', value: memberDetails?.companyId },
      { label: 'Employee Id', value: memberDetails?.employeeId },
    ],
  };
};

const getMemberSubscriptionDetails =
  (
    memberAccountData: UserAccountDetail | undefined,
    totalInvoiceOutstandingAmount: number,
    forOverView: boolean = false,
  ): { label: string, value: string | undefined }[] => {
  const memberDetail = memberAccountData?.userInfo.profile as UserProfile;

  if (!memberDetail || !memberAccountData) {
    return [];
  }

  const userAccountInfo = memberAccountData && memberAccountData.userInfo;

  if (forOverView) {
    return [
      { label: 'Plan Status', value: memberDetail.currentPlanStatus },
      { label: 'Plan', value: memberDetail.timeSlotType },
      { label: 'Plan ID', value: memberDetail.currentPlanId },
      { label: 'Plan Description', value: userAccountInfo.planDescription },
      { label: 'Discount Applied', value: userAccountInfo.discountApplied?.toString() },
      {
        label: 'Cancel Date',
        value: userAccountInfo.cancelAt ? moment(userAccountInfo.cancelAt).format('YYYY-MM-DD') : '',
      },
    ]
  }

  return [
    { label: 'Plan Status', value: memberDetail.currentPlanStatus },
    {
      label: 'Outstanding Amount', value:
          `${getFormattedAmount(totalInvoiceOutstandingAmount, userAccountInfo.currency)}`,
    },
    { label: 'Overdue (Delinquent)', value: userAccountInfo.delinquent ? 'Yes' : 'No' },
    { label: 'Plan', value: memberDetail.timeSlotType },
    { label: 'Plan ID', value: memberDetail.currentPlanId },
    { label: 'Plan Description', value: userAccountInfo.planDescription },
    {
      label: 'Payment Amount',
      value:
        userAccountInfo && userAccountInfo.paymentAmount ?
          getFormattedAmount(Number(userAccountInfo.paymentAmount / 100),
            userAccountInfo.currency) :
          undefined,
    },
    { label: 'Payment Status', value: userAccountInfo.paymentStatus },
    { label: 'Payment ID', value: userAccountInfo.paymentId },
    { label: 'Discount Applied', value: userAccountInfo.discountApplied?.toString() },
    {
      label: 'Discount Code Applied',
      value: userAccountInfo.discountCodeApplied,
    },
    {
      label: 'Start of contract',
      value: memberAccountData.userInfo.contractStart ?
        moment(memberAccountData.userInfo.contractStart).format(
        'YYYY-MM-DD',
      ) : '',
    },
    {
      label: 'End of contract',
      value: memberAccountData.userInfo.contractEnd ?
        moment(memberAccountData.userInfo.contractEnd).format(
        'YYYY-MM-DD',
      ): '',
    },
    {
      label: 'Extended contract (days)',
      value: memberAccountData.userInfo.extendedContractInDays?.toString(),
    },
    {
      label: 'Cancel Date',
      value: userAccountInfo.cancelAt ? moment(userAccountInfo.cancelAt).format('YYYY-MM-DD') : '',
    },
    { label: 'Discount End', value: userAccountInfo.discountEnd?.toString() },
  ];
};

export const getMemberAddressDetailsSelector = createSelector(
  [(state: IAppStore) => state.members.data.memberAccountData?.userInfo.profile],
  (memberDetails: UserProfile | undefined) => getMemberAddressDetails(memberDetails));

export const getMemberPersonalDetailsSelector = createSelector(
  [(state: IAppStore) => state.members.data.memberAccountData?.userInfo.profile],
  (memberDetails: UserProfile | undefined) => getMemberPersonalDetails(memberDetails));

export const getMemberSubscriptionDetailsSelector = createSelector(
  [
    (state: IAppStore) => state.members.data.memberAccountData,
    (state: IAppStore) => state.members.view.totalInvoiceOutstandingAmount,
    (_: IAppStore, forOverView: boolean) => forOverView,
  ],
  (memberAccountData: UserAccountDetail | undefined, totalInvoiceOutstandingAmount: number, forOverView: boolean) =>
    getMemberSubscriptionDetails(memberAccountData, totalInvoiceOutstandingAmount, forOverView));

export const membersColumnsSelector = createSelector(
  [
    (state: IAppStore) => state.members.data.filterDataFromMembersData,
    (_: IAppStore, reportTouse: MembersReportData) => reportTouse.memberColumnData,
    (_: IAppStore, __: MembersReportData, currentGym: Gym) => currentGym],
  (filterData, memberColumnData, currentGym) => {
    const memberTableColumns = memberColumnData.filter((column: any) => column.used);
    const columns = convertDataToViews(memberTableColumns, filterData, currentGym);
    return columns;
  },
);

export const merchandiseInvoicesSelector = createSelector(
  [(state: IAppStore) => state.members.data.memberAccountData?.invoices],
  (invoices?: IInvoice[]) => {
    if (!invoices) {
      return [];
    }

    const invoiceContainsMerchandise = (invoice: IInvoice) => {
      return !!invoice.invoiceLines
        .find((line: IInvoiceItem) =>
          line.price?.product.metadata.productCategories?.includes(ProductCategory.Goods));
    };

    return invoices.filter((invoice: IInvoice) => invoiceContainsMerchandise(invoice));
  },
);

export const invoicesSelector = createSelector(
  [(membersData?: UserAccountDetail) => membersData?.invoices],
  (invoices: IInvoice[]) =>
    invoices ? invoices.filter((invoice: IInvoice) => invoice.status !== 'draft' && invoice.status !== 'void') : [],
);

export const memberAvatarUrlSelector = createSelector(
  [getMemberDetailsData, (_: IAppStore, uid: string) => uid],
  (memberDetailsData, uid) => {
   const member = memberDetailsData.find((singleMember) => {
      return singleMember.userSettings.uid === uid;
    });
   return member ? member.userAvatarUrl : userAvatarSrc(uid);
  },
);
