import { Level, UserWorkoutSessionWithDisplayInfo, UserWorkoutStatus } from 'fitbeat.gym-core';
import { Gym } from 'fitbeat.models';
import moment from 'moment';
import React from 'react';
import { buildLeaderboardInstanceColumnsFromAccessor } from '../../leaderboards/leaderboardInstanceFilters';
import { buildLeaderboardsColumnsFromAccessor } from '../../leaderboards/leaderboardsFilters';
import { followUpDueFilter } from '../components';
import {
  aggregateFilterSelectHandler,
  dataDefinedSelect,
  filterBooleanStatus, filterContinueSignup, filterGender,
  filterGoal, filterIsNewUser, filterSkillLevel,
  filterStatus, filterTimeSlotType, influencerFilters, LeadStatusFilters, stringArrayToOptions,
} from '../components/activeFilter';
import DatePickerPreDefinedDays from '../components/datePickerPreDefinedDays';
import GreaterThanLessThanFilter from '../components/greaterThanLessThanFilter';
import MemberAvatar from '../components/MemberAvatar';
import { paymentButton } from '../components/memberPaymentButton';
import { MemberTableHeader } from '../components/memberTableHeader';
import { LeadStatusDropdown } from '../LeadStatusDropdown';

import { IFilterDataFromMembersData } from '../storeTypes';
import {
  exactTextMatchFilter,
  filterMethodContainsOrDoesntContain,
  filterMethodOnStringArray,
  getDatesForPredefinedDayFilter,
} from './filterHelpers';
import { buildWorkoutColumnsFromAccessor } from './workouts';

export const buildColumnsFromAccessors = (
  accessor: string,
  filterData: IFilterDataFromMembersData,
  currentGym: Gym) => {
  const columnFromMembers = buildMemberColumnsFromAccessor(accessor, filterData, currentGym);
  const handleKeysForMembers = Object.keys(columnFromMembers).length === 0;
  if (!handleKeysForMembers) {
    return columnFromMembers;
  }

  const columnFromLeaderboards = buildLeaderboardsColumnsFromAccessor(accessor, currentGym);
  const handleKeysForLeaderboards = Object.keys(columnFromLeaderboards).length === 0;
  if (!handleKeysForLeaderboards) {
    return columnFromLeaderboards;
  }

  const columnFromLeaderboardInstance = buildLeaderboardInstanceColumnsFromAccessor(accessor, currentGym);
  const handleKeysForLeaderboardInstance = Object.keys(columnFromLeaderboardInstance).length === 0;
  if (!handleKeysForLeaderboardInstance) {
    return columnFromLeaderboardInstance;
  }

  return buildWorkoutColumnsFromAccessor(accessor, filterData, currentGym);
};
const homeUserWorkoutStatus = (original: any) => {
  const signUpDayDiff = moment().diff(moment(original.userSignupDate), 'hours');
  if (signUpDayDiff < 24 || original.totalCompleted === 0) {
    return UserWorkoutStatus.NewUser;
  }
  return UserWorkoutStatus.Other;
};

const dateRangeFilterSelectorHandler = (filter: any, row: any) => {
  const isEmpty = !row[filter.id];
  let startDate = filter.value.startDate;
  let endDate = filter.value.endDate;
  const predefinedDayFilterDate = getDatesForPredefinedDayFilter(filter.value);
  if (predefinedDayFilterDate) {
    startDate = predefinedDayFilterDate.startDate;
    endDate = predefinedDayFilterDate.endDate;
  }

  if (
    startDate === undefined &&
    moment(row[filter.id]).isSameOrBefore(moment(endDate)) &&
    !isEmpty
  ) {
    return true;
  }
  if (
    endDate === undefined &&
    moment(row[filter.id]).isSameOrAfter(moment(startDate)) &&
    !isEmpty
  ) {
    return true;
  }
  if (
    startDate === undefined &&
    endDate === undefined
  ) {
    return true;
  }
  if (
    moment(row[filter.id]).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) &&
    moment(row[filter.id]).isSameOrBefore(moment(endDate).format('YYYY-MM-DD')) &&
    !isEmpty
  ) {
    return true;
  }
  return false;
};

function createAggregateColumn(id: string, headerName: string) {
  return {
    id,
    accessor: id,
    Header: () => <MemberTableHeader header={headerName} />,
    filterMethod: (filter: any, row: any) => aggregateFilterSelectHandler(filter, row),
    Filter: ({ filter, onChange }: any) => <GreaterThanLessThanFilter
        onChange={onChange}
        columnId={id}
      />,
    headerClassName: 'container-overflow-visible',
    filterable: true,
    sortable: true,
    used: true,
  };
}

export const buildMemberColumnsFromAccessor = (
  accessor: string,
  filterData: IFilterDataFromMembersData,
  currentGym: Gym) => {
  switch (accessor) {
    case 'isNew':
      return {
        Header: () => <MemberTableHeader header='New User' />,
        accessor,
        used: true,
        filterMethod: (filters: any, row: any) => {
          if (filters.value.length >= 2) {
            return true;
          }
          let hasFoundMatch = false;
          for (const filter of filters.value) {

            if (filter === 'isNewUser') {
              let status = UserWorkoutSessionWithDisplayInfo.getUserWorkoutStatus(row._original);
              if (!row._original.ptApprovedAt) {
                status = homeUserWorkoutStatus(row._original);
              }
              hasFoundMatch = status === UserWorkoutStatus.NewMinorUser || status === UserWorkoutStatus.NewUser;
            }

            if (filter === 'isNotNewUser') {
              let status = UserWorkoutSessionWithDisplayInfo.getUserWorkoutStatus(row._original);
              if (!row._original.ptApprovedAt) {
                status = homeUserWorkoutStatus(row._original);
              }
              hasFoundMatch = status === UserWorkoutStatus.TrialMinorUser || status === UserWorkoutStatus.TrialUser ||
                status === UserWorkoutStatus.Other;
            }
          }
          return hasFoundMatch;
        },
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => filterIsNewUser(filter, onChange),
        filterable: true,
        sortable: true,
        Cell: ({ original }: any) => {
          let status = UserWorkoutSessionWithDisplayInfo.getUserWorkoutStatus(original);
          if (!original.ptApprovedAt) {
            status = homeUserWorkoutStatus(original);
          }
          return status === UserWorkoutStatus.NewUser ? <span className='new-user-circle'></span> : '';
        },
      };
    case 'member':
      return {
        Header: () => <MemberTableHeader header='Member' />,
        accessor,
        used: true,
        filterable: false,
        width: 100,
        Cell: ({ original }: any) => <MemberAvatar row={original} />,
        sortable: false,
      };
    case 'continueSignupToken':
      return {
        Header: () => <MemberTableHeader header='Signup Link' />,
        used: true,
        width: 120,
        accessor,
        headerClassName: 'container-overflow-visible',
        filterMethod: (filters: any, row: any) => {
          let hasFoundMatch = false;
          const isEmpty = !row[filters.id];
          const hasLink = row[filters.id];
          for (const filter of filters.value) {
            if (filter === 'None' && isEmpty) {
              hasFoundMatch = true;
            }
            if (filter === 'Continue' && hasLink) {
              hasFoundMatch = true;
            }
          }
          return hasFoundMatch;
        },
        filterable: true,
        sortable: true,
        Filter: ({ filter, onChange }: any) => filterContinueSignup(filter, onChange),
        Cell: ({ original }: any) => (paymentButton(original)),
      };
    case 'fullname':
      return {
        Header: () => <MemberTableHeader header='Name' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
          Cell: ({ original }: any) => original?.fullname ?? '',
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 140,
      };
    case 'email':
      return {
        Header: () => <MemberTableHeader header='Email' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 230,
      };
    case 'mobileNumber':
      return {
        Header: () => <MemberTableHeader header='Mobile Number' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 130,
      };
    case 'userSignupDate':
      return {
        Header: () => <MemberTableHeader header='User Signup Date' />,
        accessor,
        used: true,
        filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
        headerClassName: 'container-overflow-visible',
        filterable: true,
        sortable: true,
        Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
          type={accessor}
          />,
        width: 230,
        Cell: ({ original }: any) => moment.tz(original.userSignupDate, currentGym.timezone).format('YYYY-MM-DD'),
      };

    case 'employeeId':
      return {
        Header: () => <MemberTableHeader header='Employee Id' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        headerClassName: 'container-overflow-visible',
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 230,
      };

    case 'companyId':
      return {
        Header: () => <MemberTableHeader header='Company Id' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        headerClassName: 'container-overflow-visible',
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 230,
      };

      case 'currentPlanStatus':
      return {
        Header: () => <MemberTableHeader header='Current Plan Status' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        filterMethod: exactTextMatchFilter,
        Filter: ({ filter, onChange }: any) => filterStatus(filter, onChange),
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'currentPlanId':
      return {
        Header: () => <MemberTableHeader  header='Current Plan Id'/>,
        accessor,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        used: true,
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 120,
      };
    case 'planDescription':
      return {
        Header: () => <MemberTableHeader header='Plan Description' />,
        accessor,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        used: true,
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 120,
      };
    case 'timeSlotType':
      return {
        Header: () => <MemberTableHeader header='Time Slot Type' />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => filterTimeSlotType(filter, onChange),
        filterable: true,
        sortable: true,
        width: 120,
      };
    case 'planHistory':
      return {
        Header: () => <MemberTableHeader header='Plan History' />,
        accessor,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        used: true,
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 120,
      };
    case 'followUpDue':
      return {
        Header: () => <MemberTableHeader header='Follow Up Due'/>,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        filterable: true,
        sortable: true,
        width: 650,
        filterMethod: (filters: any, row: any) => {
          const { followUpDate } = row;
          if (filters.value.length === 4) {
            return true;
          }

          for (const filter of filters.value) {
            const isUpcomingFilterCheck = followUpDate &&
              filter === 'notDueYet' &&
              moment(followUpDate).isAfter(moment(), 'day');

            const isPastFilterCheck = followUpDate &&
              filter ===  'pastDue' &&
              moment(followUpDate).isBefore(moment(), 'day');

            const isOnSameDay = followUpDate && filter ===  'dueToday' &&
              moment(followUpDate).isSame(moment(), 'day');

            const isUnassignedFilterCheck = !followUpDate &&
              filter ===  'unassigned';

            if (isUpcomingFilterCheck ||
              isOnSameDay ||
              isPastFilterCheck ||
              isUnassignedFilterCheck) {
              return true;
            }
          }
          return false;
        },
        Filter: ({ filter, onChange }: any) => followUpDueFilter(filter, onChange),
        Cell: ({ original }: any) => {
          const { followUpDate } = original;
          const isUpcomingDate = followUpDate && moment(followUpDate).isAfter(moment(), 'day');
          const isPastDate = followUpDate && moment(followUpDate).isBefore(moment(), 'day');
          const isOnSameDay = followUpDate && moment(followUpDate).isSame(moment(), 'day');

          if (isOnSameDay) {
            return 'Due';
          }
          if (isUpcomingDate) {
            return 'Not due yet';
          }
          if (isPastDate) {
            return 'Past due';
          }
          return 'Unassigned';
        },
      };
    case 'currentPlanStartDate':
      return {
        Header: () => <MemberTableHeader header='Plan Start Date' />,
        accessor,
        filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
        Cell: ({ original }: any) => {
          return original?.stripeSubscriptionData?.subscriptionStartsAt
                ? moment(original.stripeSubscriptionData.subscriptionStartsAt).format('YYYY-MM-DD')
                : '';
        },
        Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
          type={accessor}/>,
        used: true,
        headerClassName: 'container-overflow-visible',
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'teams':
      return {
        Header: () => <MemberTableHeader header='Team(s)' />,
        accessor,
        Cell: ({ original }: any) => {
          if (!original.teams || original.teams.length === 0) {
            return <p className='report-cell-text'>{'-'}</p>;
          }
          return original.teams.join(',');
        },
        filterMethod: (filter: any, rows: any) => {
          return filterMethodOnStringArray(filter.value, rows.teams);
        },
        used: true,
        headerClassName: 'container-overflow-visible',
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'followUpDate':
      return {
        Header: () => <MemberTableHeader header='Follow Up Date' />,
        accessor,
        Cell: ({ original }: any) => {
          return original.followUpDate ?
            moment(original.followUpDate).format('YYYY-MM-DD') : '';
        },
        filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
         type={accessor}/>,
        used: true,
        filterable: true,
        sortable: true,
        width: 650,
      };
    case 'dateOfBirth':
      return {
        Header: () => <MemberTableHeader header='Date of Birth' />,
        accessor,
        filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
         type={accessor}/>,
        used: true,
        filterable: true,
        sortable: true,
        width: 230,
      };
    case 'gender':
      return {
        Header: (props: any) => <MemberTableHeader header='Gender' {...props} />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => filterGender(filter, onChange),
        filterable: true,
        sortable: true,
      };
    case 'skillLevel':
      return {
        Header: (props: any) => <MemberTableHeader header='User Skill Level' {...props} />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => filterSkillLevel(filter, onChange),
        Cell: ({ original }: any) => {
          if (original?.skillLevel === Level.Beginner) {
            return 'Basic';
          }
          if (original?.skillLevel === Level.Basic) {
            return 'Intermediate';
          }
          if (original?.skillLevel === Level.Intermediate) {
            return 'Advanced';
          } else {
            return '';
          }
        },
        filterable: true,
        sortable: true,
      };
    case 'medium':
      return {
        Header: () => <MemberTableHeader header='Signup Medium' />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => dataDefinedSelect(filter, onChange,
          stringArrayToOptions([...filterData.mediums, 'Unassigned'])),
        filterable: true,
        sortable: true,
      };

    case 'source':
      return {
        Header: () => <MemberTableHeader header='Signup Source' />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => dataDefinedSelect(filter, onChange,
          stringArrayToOptions([...filterData.sources, 'Unassigned'])),
        filterable: true,
        sortable: true,
      };
    case 'attributionSourceId':
      return {
        Header: () => <MemberTableHeader header='Atrribution Source' />,
        accessor,
        used: true,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 130,
      };
    case 'campaign':
      return {
        Header: () => <MemberTableHeader header='Signup Campaign' />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => dataDefinedSelect(filter, onChange,
          stringArrayToOptions([...filterData.campaigns, 'Unassigned'])),
        filterable: true,
        sortable: true,
      };
    case 'leadStatus':
      return {
        Header: () => <MemberTableHeader header='Lead Status' />,
        accessor,
        Cell: ({ original }: any) => {
          if (!original.leadStatus) {
            return <p className='report-cell-text'>{'-'}</p>;
          }

          return <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <LeadStatusDropdown
              activeLeadStatus={original.leadStatus}
              uid={original.uid}
            /></div>;
        },
        used: true,
        headerClassName: 'container-overflow-visible',
        filterMethod: exactTextMatchFilter,
        Filter: ({ filter, onChange }: any) => LeadStatusFilters(filter, onChange),
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'longTermGoal':
      return {
        Header: () => <MemberTableHeader header='Long Term Goal' />,
        accessor,
        used: true,
        filterMethod: exactTextMatchFilter,
        headerClassName: 'container-overflow-visible',
        Filter: ({ filter, onChange }: any) => filterGoal(filter, onChange),
        filterable: true,
        sortable: true,
        width: 150,
      };
    case 'planExpiresAt':
      return {
        Header: () => <MemberTableHeader header='Plan expiry date' />,
        accessor,
        filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
        headerClassName: 'container-overflow-visible',
        Cell: ({ original }: any) => {
          return original.planExpiresAt ?
              moment(original.planExpiresAt).format('YYYY-MM-DD') : '';
        },
        Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          onChange={onChange}
         type={accessor}/>,
        used: true,
        filterable: true,
        sortable: true,
        width: 230,
      };
    case 'isStreamingUser':
      return {
        Header: () => <MemberTableHeader header='Influencer' />,
        accessor,
        Cell: ({ original }: any) =>
          original && original.isStreamingUser ? 'Streaming' : 'Non Streaming',
        used: true,
        headerClassName: 'container-overflow-visible',
        filterMethod: (filters: any, row: any) => {
          for (const filter of filters.value) {
            if (filter === 'streaming' && row.isStreamingUser === true) {
              return true;
            }
            if (filter === 'non-streaming' && !row.isStreamingUser) {
              return true;
            }
          }
          return false;
        },
        Filter: ({ filter, onChange }: any) => influencerFilters(filter, onChange),
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'delinquent':
      return {
        Header: () => <MemberTableHeader header='Delinquent' />,
        accessor,
        used: true,
        headerClassName: 'container-overflow-visible',
        filterMethod: exactTextMatchFilter,
        Filter: ({ filter, onChange }: any) => filterBooleanStatus(filter, onChange),
        filterable: true,
        sortable: true,
        width: 200,
      };
    case 'stripeUserId':
      return {
        Header: () => <MemberTableHeader header='Customer Id' />,
        accessor,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        used: true,
        filterAll: true,
        filterable: true,
        sortable: true,
        width: 120,
      };
    case 'uid':
      return {
        Header: () => <MemberTableHeader header='UID' />,
        accessor,
        filterMethod: (filter: any, rows: any) =>
          filterMethodContainsOrDoesntContain(filter, rows, accessor),
        used: true,
        filterAll: true,
        filterable: true,
        sortable: true,
      };
      case 'totalCompleted':
        return createAggregateColumn(accessor, 'Total Workouts Completed');
      case 'totalCancelledStudio':
        return createAggregateColumn(accessor, 'Total Cancelled Studio');
      case 'totalMissedStudio':
        return createAggregateColumn(accessor, 'Total Missed Studio');
      case 'totalMissedHome':
          return createAggregateColumn(accessor, 'Total Missed Home');
      case 'totalCompletedHome':
        return createAggregateColumn(accessor, 'Total Completed Home');
      case 'totalHRCompleted':
        return createAggregateColumn(accessor, 'Total HR Completed');
      case 'totalBurnCompleted':
        return createAggregateColumn(accessor, 'Total Burn Completed');
      case 'totalBuildCompleted':
        return createAggregateColumn(accessor, 'Total Build Completed');
      case 'totalBalanceCompleted':
        return createAggregateColumn(accessor, 'Total Balance Completed');
      case 'totalCustomisedCompleted':
        return createAggregateColumn(accessor, 'Total Customised Completed');
      case 'totalCompletedStudio':
        return createAggregateColumn(accessor, 'Total Completed Studio');
      case 'totalStudio28mins':
          return createAggregateColumn(accessor,'Total Studio 28mins');
      case 'totalStudio48mins':
          return createAggregateColumn(accessor, 'Total Studio 48mins');
      case 'totalHome17mins':
          return createAggregateColumn(accessor, 'Total Home 17mins');
      case 'totalHome29mins':
          return createAggregateColumn(accessor, 'Total Home 29mins');
      case 'totalHome42mins':
          return createAggregateColumn(accessor, 'Total Home 42mins');
      case 'firstWorkout':
        return {
          ...createAggregateColumn(accessor, 'First Workout Date'),
          filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
          Cell: ({ original }: any) => {
            return original.firstWorkout
                  ? moment(original.firstWorkout).format('YYYY-MM-DD')
                  : '';
          },
          Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
          filter={filter}
          onChange={onChange}
         type={accessor}/>,
        };
      case 'lastWorkout':
        return {
          ...createAggregateColumn(accessor, 'Last workout Date'),
          filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
          Cell: ({ original }: any) => {
            return original.lastWorkout
                  ? moment(original.lastWorkout).format('YYYY-MM-DD')
                  : '';
          },
          Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
            filter={filter}
            onChange={onChange}
           type={accessor}/>,
        };
      case 'totalDurationInMinutes':
        return createAggregateColumn(accessor, 'Total Workout Minutes');
      case 'totalEffortPoints':
        return createAggregateColumn(accessor, 'Total Effort Points');
      case 'totalCalories':
        return createAggregateColumn(accessor, 'Total Calories');
      case 'contractEnd':
        return {
          id: 'contractEnd',
          Header: () => <MemberTableHeader header='Contract Ends' />,
          accessor,
          filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
          headerClassName: 'container-overflow-visible',
          Cell: ({ original }: any) => {
            return original.contractEnd
                  ? moment(original.contractEnd).format('YYYY-MM-DD')
                  : '';
          },
          Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
            filter={filter}
            onChange={onChange}
           type={accessor}/>,
          used: true,
          filterable: true,
          sortable: true,
        };
      case 'cancelAt':
        return {
          id: 'cancelAt',
          Header: () => <MemberTableHeader header='Subscription Cancels At' />,
          accessor,
          filterMethod: (filter: any, row: any) => dateRangeFilterSelectorHandler(filter, row),
          Cell: ({ original }: any) => {
            return original.cancelAt
                  ? moment(original.cancelAt).format('YYYY-MM-DD')
                  : '';
          },
          Filter: ({ filter, onChange }: any) => <DatePickerPreDefinedDays
            filter={filter}
            onChange={onChange}
            type={accessor}/>,
          used: true,
          headerClassName: 'container-overflow-visible',
          filterable: true,
          sortable: true,
        };
    default:
      return {};
  }
};

export const availableMemberDataAccessors = [
  {
    accessor: 'isNew',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'member',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'fullname',
    used: true,
    sortable: true,
    width: 140,
  }, {
    accessor: 'email',
    used: true,
    sortable: true,
    width: 230,
  }, {
    accessor: 'mobileNumber',
    used: true,
    sortable: true,
    width: 130,
  }, {
    accessor: 'userSignupDate',
    used: true,
    sortable: true,
    width: 220,
  }, {
    accessor: 'currentPlanStatus',
    used: true,
    sortable: true,
    width: 200,
  }, {
    accessor: 'planDescription',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'currentPlanId',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'employeeId',
    used: true,
    sortable: true,
    width: 120,
  },
  {
    accessor: 'companyId',
    used: true,
    sortable: true,
    width: 120,
  },
   {
    accessor: 'followUpDue',
    used: true,
    sortable: true,
    width: 100,
  }, {
    accessor: 'followUpDate',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'planExpiresAt',
    used: true,
    sortable: true,
    width: 220,
  },
   {
    accessor: 'delinquent',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'timeSlotType',
    used: true,
    sortable: true,
    width: 120,
  }, {
    accessor: 'planHistory',
    used: true,
    sortable: true,
    width: 120,
  }, {
    accessor: 'dateOfBirth',
    used: true,
    sortable: true,
    width: 220,
  }, {
    accessor: 'gender',
    used: true,
    sortable: true,
    width: 110,
  },
  {
    accessor: 'medium',
    used: true,
    sortable: true,
    width: 110,
  },
  {
    accessor: 'teams',
    used: true,
    sortable: true,
    width: 200,
  },
  {
    accessor: 'currentPlanStartDate',
    used: true,
    sortable: true,
    width: 200,
  },
  {
    accessor: 'skillLevel',
    used: true,
    sortable: true,
    width: 110,
  },
  {
    accessor: 'source',
    used: true,
    sortable: true,
    width: 110,
  },
  {
    accessor: 'campaign',
    used: true,
    sortable: true,
    width: 110,
  },
  {
    accessor: 'isStreamingUser',
    used: true,
    sortable: true,
    width: 150,
  }, {
    accessor: 'longTermGoal',
    used: true,
    sortable: true,
    width: 150,
  }, {
    accessor: 'stripeUserId',
    used: true,
    sortable: true,
    width: 150,
  }, {
    accessor: 'uid',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'continueSignupToken',
    used: true,
    sortable: true,
    width: 150,
  }, {
    accessor: 'leadStatus',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'totalCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalCancelledStudio',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalMissedStudio',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalCompletedStudio',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalStudio28mins',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalStudio48mins',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalHome17mins',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalHome29mins',
    used: true,
    sortable: true,
    width: 220,
  },
  {
      accessor: 'totalHome42mins',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalCompletedHome',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalMissedHome',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalHRCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalBurnCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalBuildCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalBalanceCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalCustomisedCompleted',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalDurationInMinutes',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalEffortPoints',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'totalCalories',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'lastWorkout',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'firstWorkout',
    used: true,
    sortable: true,
    width: 220,
  },
  {
    accessor: 'contractEnd',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'cancelAt',
    used: true,
    sortable: true,
    width: 150,
  },
  {
    accessor: 'attributionSourceId',
    used: true,
    sortable: true,
    width: 150,
  }];
